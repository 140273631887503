import React from "react"

export default function Home() {
  return (
    <>
      <h1>4Pi.nz</h1>
      <p>not yet</p>
    </>
  )
}
